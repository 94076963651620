<template src="./topmedRoom.html" />

<script>
import { computed } from "vue"
import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"

export default {
  name: "TopmedRoom",
  mixins: [mixins],
  data() {
    return {
      topmedUrlOk: false,
      isWkWebView: false,
    }
  },
  setup () {
    const store = useStore()

    return {
      getTopmedUrl: () => store.dispatch("getTopmedUrl"),
      topmedUrl: computed(() => store.getters.topmedUrl),
    }    
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.$vs.loading()

      if (window.webkit && window.webkit.messageHandlers) {
        this.ios()
      }
      this.getUrl()
    },

    async getUrl() {
      await this.getTopmedUrl()
      
      if (!this.topmedUrl) {
        this.$emit("showModal", "modal-wait-24h")
      } else {
        const win = window.open(this.topmedUrl, "_blank")
        win.focus()
        this.topmedUrlOk = true
      }
      this.$vs.loading.close()
    },

    ios() {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.einsteinMessageHandler) {
        const msg = this.topmedUrl;
        window.webkit.messageHandlers.einsteinMessageHandler.postMessage(msg)
      }
    },
  },
};
</script>

<style lang="scss" src="./topmedRoom.scss" />
